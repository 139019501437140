<template>
  <div>
    <v-text-field
      label="Name"
      v-model="form.long_name"
      type="text"
      :disabled="loading"
      :rules="[rules.required, rules.nameMaxLength]"
      @input="checker"
      autofocus
      hint="Name of the circle i.e 'Goat Mania'"
      color="primary darken-2"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="checkingNameAvailability"
          :width="2"
          :size="20"
          color="primary darken-2"
          indeterminate
        ></v-progress-circular>

        <template v-else>
          <v-icon
            v-if="nameIsAvailable || form.long_name == ''"
            color="primary darken-2"
          >
            {{ mdiCheck }}
          </v-icon>
          <v-icon v-else color="error">
            {{ mdiCancel }}
          </v-icon>
        </template>
      </template>
    </v-text-field>

    <v-expand-transition>
      <div :show="slugifiedName" class="mb-2">
        <p class=" mb-0 primary--text text--darken-2 caption">
          The slug version would be
        </p>
        <p>
          <v-sheet color="grey lighten-4" class="py-1 px-2">
            {{ slugifiedName }}
          </v-sheet>
        </p>
        <p>
          Use
          <v-chip small class="white--text" color="primary darken-2">
            {{ slugifySeparatorName }}
          </v-chip>
          as separator

          <v-radio-group v-model="slugify_separator" hide-details row>
            <v-radio color="primary darken-2" label="dash" value="-"></v-radio>
            <v-radio
              color="primary darken-2"
              label="underscore"
              value="_"
            ></v-radio>
            <v-radio
              color="primary darken-2"
              label="nuthin"
              value=" "
            ></v-radio>
          </v-radio-group>
        </p>
      </div>
    </v-expand-transition>

    <v-textarea
      label="About"
      v-model="form.description"
      auto-grow
      rows="3"
      type="text"
      :rules="[rules.required]"
      :disabled="loading"
      hint="What's this circle about i.e 'Pictures of Goats'"
      color="primary darken-2"
    ></v-textarea>

    <!-- Circle theme color picker  -->
    <template v-if="showThemeColor">
      <v-subheader class="px-0 body-1">Circle theme color</v-subheader>
      <v-card height="60px" width="400px" flat>
        <v-sheet width="100%" height="100%" :color="themeColor"></v-sheet>
        <v-overlay absolute opacity="0">
          <v-btn outlined color="white" @click="openColorPicker = true">
            Change
            <v-icon>
              {{ mdiPalette }}
            </v-icon>
          </v-btn>
        </v-overlay>
      </v-card>
      <v-card-subtitle class="caption pl-0"
        >Please don't pick light colors :pray:</v-card-subtitle
      >
    </template>
    <!-- Circle theme color picker -->

    <!-- Avatar -->
    <v-subheader class="px-0 body-1">Circle Avatar</v-subheader>

    <image-uploader
      v-bind:previewImage="{
        style: 'border-radius: 50px',
        height: '100px',
        width: '100px',
        contain: true
      }"
      v-bind:cardSheet="{
        style: 'border-radius: 50px',
        class: 'd-flex align-center',
        height: '100px',
        width: '100px'
      }"
      v-bind:card="{
        class: 'mt-3 mx-auto d-flex justify-center',
        width: '100px',
        height: '100px'
      }"
      v-bind:image.sync="form.display_picture"
    ></image-uploader>

    <p class="mb-0 caption grey--text text-center mt-2">200 x 200 abeg</p>

    <!-- Banner -->

    <v-subheader class="px-0 body-1">Circle Banner</v-subheader>

    <image-uploader
      v-bind:previewImage="{
        height: '130px',
        width: '100%',
        contain: false
      }"
      v-bind:cardSheet="{
        class: 'd-flex align-center',
        height: '130px',
        width: '100%'
      }"
      v-bind:card="{
        class: 'mt-3',
        width: '100%',
        height: '130px'
      }"
      v-bind:image.sync="form.banner"
      @uploaded-image-src="bannerChanged"
    ></image-uploader>

    <p class="caption grey--text text-center mt-11">
      iono tbh, wide sha :)
    </p>

    <v-divider></v-divider>

    <v-card-actions class="d-flex justify-space-between">
      <v-btn color="color-purple purple-outline" disabled>
        Back
      </v-btn>

      <v-btn
        depressed
        :disabled="
          form.long_name == '' ||
            slugifiedName == '' ||
            form.description == '' ||
            form.banner == '' ||
            form.display_picture == '' ||
            !nameIsAvailable
        "
        color="bg-purple"
        @click="moveStep('forward')"
      >
        Next
      </v-btn>
    </v-card-actions>

    <color-picker
      :show.sync="openColorPicker"
      :themeColor.sync="themeColor"
    ></color-picker>
  </div>
</template>
<script>
import http from '@/plugins/http';
import ImageUploader from '@/components/helpers/image-uploader.vue';
import ColorPicker from '@/components/helpers/color-picker.vue';
// icons
import { mdiCancel, mdiCheck, mdiPalette } from '@mdi/js';
export default {
  name: 'CircleProfile',
  components: {
    ImageUploader,
    ColorPicker
  },
  props: ['showThemeColor', 'mainColor'],
  data() {
    return {
      form: {
        long_name: '',
        description: '',
        display_picture: '',
        banner: ''
      },
      slugify_separator: '-',
      loading: false,
      timeout: null,
      rules: {
        required: value => !!value || 'Write somtin na',
        nameMaxLength: value =>
          value.length < 30 || 'Too long, not more than 30 characters :/'
      },
      checkingNameAvailability: false,
      nameIsAvailable: null,
      themeColor: this.mainColor,
      openColorPicker: false,
      // icons
      mdiCheck,
      mdiPalette,
      mdiCancel
    };
  },
  computed: {
    slugifiedName() {
      if (this.form.long_name == '') {
        return false;
      }
      return this.slugify(this.form.long_name, this.slugify_separator);
    },
    slugifySeparatorName() {
      switch (this.slugify_separator) {
        case '-':
          return 'dash';
        case '_':
          return 'underscore';
        case ' ':
          return 'no spaces';
        default:
          return 'dash';
      }
    }
  },

  watch: {
    mainColor: function(color) {
      this.themeColor = color;
    },
    themeColor: function(color) {
      this.$emit('update:mainColor', color);
    }
  },

  methods: {
    moveStep(direction) {
      if (this.form.long_name == '' || this.form.description == '') {
        return false;
      }

      this.$emit('move-step', {
        direction,
        data: {
          ...this.form,
          slug: this.slugifiedName
        }
      });

      return;
    },
    slugify(text, separator = '-') {
      return (
        text
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, separator) // Replace spaces with -
          // eslint-disable-next-line no-useless-escape
          .replace(/[^\w\-]+/g, '') // Remove all non-word chars
          // eslint-disable-next-line no-useless-escape
          .replace(/\-\-+/g, separator) // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
      ); // Trim - from end of text
    },
    isCircleNameAvailable(slug) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      this.checkingNameAvailability = true;
      http
        .get(`/v1/circle/${slug}/`, { headers })
        .then(response => {
          if (response.status == 200) {
            console.log('unavailable!');
            this.nameIsAvailable = false;
            return false;
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.nameIsAvailable = true;
            return true;
          }
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't check name availability, please try again :3",
            style: 'error'
          });
        })
        .finally(() => {
          this.checkingNameAvailability = false;
        });
    },
    checker: function(name) {
      // Clear the timeout if it has already been set.
      // This will prevent the previous task from executing
      // if it has been less than <MILLISECONDS>
      clearTimeout(this.timeout);
      let self = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.timeout = setTimeout(function() {
        console.log('Input Value:', name);
        self.isCircleNameAvailable(self.slugifiedName);
      }, 2000);
    },
    bannerChanged(src) {
      this.$emit('banner-changed', src);
    }
  }
};
</script>
<style scoped></style>
