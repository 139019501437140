<template>
  <div>
    <v-subheader class="px-0 body-1">Rules</v-subheader>
    <p class="mb-1 caption grey--text">
      For example 'Cute goats only!'
    </p>
    <template v-for="(rule, id) in rules">
      <v-text-field
        :key="id"
        @keydown.enter="addRule"
        v-model="rules[id].text"
        type="text"
        hide-details
        @click="rules[id].emptyError = false"
        dense
        outlined
        :error="rules[id].emptyError"
        color="primary"
      >
        <template v-slot:append-outer>
          <v-btn @click="deleteRule(id)" icon :disabled="id == 0">
            <v-icon color="red lighten-2" :disabled="id == 0">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <v-btn
      text
      depressed
      outlined
      color="primary darken-2"
      class="mb-3 mt-1"
      small
      @click="addRule"
      >Add Rule</v-btn
    >

    <v-divider></v-divider>

    <v-card-actions class="d-flex justify-space-between">
      <v-btn color="color-purple purple-outline" @click="moveStep('back')">
        Back
      </v-btn>

      <v-btn
        depressed
        :disabled="!allSet"
        color="bg-purple"
        @click="submit"
        dark
      >
        Submit
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
// icons
import { mdiClose } from '@mdi/js';
export default {
  name: 'CircleDetails',
  data: () => ({
    rules: [{ text: '', emptyError: false }],
    unformattedRules: '',
    // icons
    mdiClose
  }),
  methods: {
    deleteRule(id) {
      this.rules.splice(id, 1);
    },
    prepareRules() {
      return this.rules
        .map(r => {
          return r.text;
        })
        .join('\n');
    },
    addRule() {
      if (this.rules[this.rules.length - 1].text == '') {
        this.rules[this.rules.length - 1].emptyError = true;
        return;
      }
      this.rules.push({ text: '', emptyError: false });
    },
    moveStep(direction) {
      this.$emit('move-step', { direction });
    },
    submit() {
      let unformattedRules = this.prepareRules();
      this.$emit('submit', { data: unformattedRules });
    }
  },
  computed: {
    allSet() {
      return this.rules.every(r => {
        return !r.emptyError;
      });
    }
  }
};
</script>

<style></style>
