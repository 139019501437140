<template>
  <div>
    <v-toolbar tile dark :color="styles.mainColor" dense>
      <v-btn icon @click="back">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <v-toolbar-title>
        <div class="d-flex flex-column">
          <small style="font-size: .8rem">Create Circle</small>
          <p
            class="text-capitalize font-weight-bold mb-0"
            style="font-size: 1.1rem"
          >
            {{ stepTitle }}
          </p>
        </div>
      </v-toolbar-title>

      <v-spacer> </v-spacer>
      <v-toolbar-items class="d-flex align-center">
        <v-avatar
          class="white--text text-capitalize "
          size="28px"
          color="primary lighten-2"
        >
          <span style="font-size: 15px">
            {{ step }}
          </span>
        </v-avatar>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="mt-1 px-3">
      <!-- <p class="subtitle-1 mb-0 dark--text">{{ stepTitle }}</p> -->
      <v-window v-model="step" touchless>
        <v-window-item :value="1">
          <profile-section
            @move-step="moveStep"
            @banner-changed="handleBanner"
            :showThemeColor="showThemeColor"
            :mainColor.sync="styles.mainColor"
          ></profile-section>
        </v-window-item>
        <v-window-item :value="2">
          <details-section
            @move-step="moveStep"
            @submit="submit"
          ></details-section>
        </v-window-item>
      </v-window>
    </v-card-text>
    <!-- <v-divider></v-divider> -->

    <!-- <v-card-actions class="d-flex justify-space-between">
      <v-btn
        color="color-purple purple-outline"
        :disabled="step == 1"
        @click="step--"
      >
        Back
      </v-btn>

      <v-btn v-if="step == 1" depressed color="bg-purple" dark @click="step++">
        Next
      </v-btn>

      <v-btn depressed v-if="step == 2" color="bg-purple" dark>
        Create :)
      </v-btn>
    </v-card-actions> -->
  </div>

  <!-- Join Circles bottom sheet -->
</template>

<script>
import http from '@/plugins/http';
import { ProfileSection, DetailsSection } from './sections';
import { ignoreColors } from '@/utils/colors';
import mainColor from 'rgbaster';
// icons
import { mdiArrowLeft } from '@mdi/js';

export default {
  name: 'CreateCircle',
  components: {
    ProfileSection,
    DetailsSection
  },
  data: () => ({
    step: 1,
    form: {},
    styles: {
      mainColor: 'primary'
    },
    showThemeColor: false,
    // icons
    mdiArrowLeft
  }),
  methods: {
    back: function() {
      this.$router.back();
    },
    moveStep({ direction, data }) {
      if (data) {
        this.form = {};

        this.form.long_name = data.long_name;
        this.form.display_picture = data.display_picture;
        this.form.banner = data.banner;
        this.form.description = data.description;
        this.form.slug = data.slug;
      }
      switch (direction) {
        case 'forward':
          this.step++;
          break;
        case 'back':
          this.step--;
          break;
        case 'backward':
          this.step--;
          break;
        default:
          break;
      }
    },
    submit({ data }) {
      this.form.rules = data;
      // then submit it!

      this.$store.dispatch('TOGGLE_LOADER');

      const headers = {
        Authorization: `token ${this.$store.getters.token}`,
        'Content-Type': 'multipart/form-data'
      };

      let formData = new FormData();

      formData.append('name', this.form.long_name);

      formData.append('slug', this.form.slug);

      formData.append('description', this.form.description);
      formData.append('display_picture', this.form.display_picture);
      formData.append('banner', this.form.banner);
      formData.append('rules', this.form.rules);
      formData.append('styles', JSON.stringify(this.styles));

      http
        .post('/v1/circle/new/create/', formData, { headers })
        .then(response => {
          if (response.data.success) {
            // circle was created successfully :)
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Circle created successfully',
              style: 'success'
            });

            this.$store.dispatch('FETCH_USER');

            this.$router.push(`/c/${response.data.data.slug}`);
          }
        })
        .catch(error => {
          console.log('Error =>', error);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_LOADER');
        });
    },
    async getMainColor(banner, remoteImage = true) {
      let bg_url =
        banner ||
        'https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg';

      let proxyUrl = 'https://proxy.mybubbl.me/';
      let result = 'primary';

      if (remoteImage) {
        result = await mainColor(proxyUrl + bg_url, {
          scale: 0.5,
          ignore: ignoreColors
        });
      } else {
        result = await mainColor(banner, {
          scale: 0.5,
          ignore: ignoreColors
        });
      }

      // var base64 = getBase64Image(document.getElementById("imageid"));
      this.styles.mainColor = result[0].color;
      this.showThemeColor = true;
    },
    handleBanner(banner) {
      this.getMainColor(banner, false);
    }
  },
  computed: {
    stepTitle() {
      switch (this.step) {
        case 1:
          return 'Profile';
        case 2:
          return 'Details';
        default:
          return 'Create Circle';
      }
    }
  },
  head: {
    title: 'New Circle'
  }
};
</script>

<style scoped></style>
